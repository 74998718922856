.ng-scope {
    margin-top: 0px;
    .panel {
        color: #666666;
        background-color: #ffffff;
        border: none;
        border-radius: 5px;
        position: relative;
        margin-bottom: 24px;
        box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.25);
        animation-duration: 0.5s;
        .panel-body {
            .flex-row {
                padding: 15px 22px;
                height: 100%;
                .ul-add {
                    width: 49%;
                    .row {
                        display: flex;
                        .list-bottom-add {
                            font-size: 16px;
                            font-weight: bold;
                            color: black;
                            margin-bottom: 20px;
                            border: 1px solid transparent;
                            border-radius: 4px;
                            padding: 10px;
                        }
                        .ul-add-bottom {
                            width: 50%;
                        }
                        @media screen and (max-width: 769px) {
                            .ul-add-bottom {
                                width: 100%;
                            }
                        }
                        .devices {
                            width: 50%;
                        }
                    }
                    @media screen and (max-width: 769px) {
                        .row {
                            flex-direction: column;
                            .list-bottom-add {
                                font-size: 14px;
                            }
                        }
                    }
                    .list-add {
                        display: flex;
                        padding: 10px;
                        background-color: #d9edf7;
                        border-color: #bce8f1;
                        margin-bottom: 20px;
                        border: 1px solid transparent;
                        border-radius: 4px;
                        justify-content: space-between;
                        font-size: 16px;
                        font-weight: bold;
                        color: black;

                        button {
                            color: white;
                            border-color: transparent;
                            border-radius: 5px;
                            transition: all 0.1s ease;
                            display: inline-block;
                            padding: 6px 12px;
                            margin-bottom: 0;
                            font-size: 14px;
                            font-weight: normal;
                            line-height: 1.42857143;
                            text-align: center;
                            white-space: nowrap;
                            vertical-align: middle;
                            -ms-touch-action: manipulation;
                            touch-action: manipulation;
                            cursor: pointer;
                            -webkit-user-select: none;
                            -moz-user-select: none;
                            -ms-user-select: none;
                            user-select: none;
                            background-image: none;
                            border: 1px solid transparent;
                            outline-style: none;
                            &:hover {
                                transform: scale(1.2);
                                -webkit-transform: scale(1.2);
                                -moz-transform: scale(1.2);
                                -ms-transform: scale(1.2);
                                -o-transform: scale(1.2);
                            }
                        }
                    }
                }
                @media screen and (max-width: 769px) {
                    .ul-add {
                        width: 100%;
                    }
                }
            }
            @media screen and (max-width: 769px) {
                .flex-row {
                    flex-wrap: wrap;
                }
            }
        }

        .flex-center {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-align: center;
            align-items: center;
            flex-basis: 49%;
            .userpic {
                border: 1px dashed #b8b8b8;
                width: 202px;
                height: 202px;
                position: relative;
                cursor: pointer;
                .star {
                    width: 40px;
                    position: absolute;
                    top: -16px;
                    right: -14px;
                    z-index: 20;
                    max-width: 100%;
                    max-height: 100%;
                }
                .userpic-wrapper {
                    width: 200px;
                    height: 200px;
                    overflow: hidden;
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-pack: center;
                    justify-content: center;
                    -ms-flex-align: center;
                    align-items: center;
                    position: relative;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }
            .form-group {
                .list-name {
                    .font-default {
                        font-size: 16px;
                        font-weight: bold;
                        color: black;
                        margin-bottom: 20px;
                        border: 1px solid transparent;
                        border-radius: 4px;
                        padding: 10px;
                    }
                }
            }
        }
    }
}
.animated {
    animation-duration: 0.5s;
}
.chip {
    width: 32px;
    padding-right: 10px;
}
.chip-vk {
    padding: 0;
    border-radius: 100px;
    vertical-align: middle;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
}

@-webkit-keyframes zoomIn {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }

    50% {
        opacity: 1;
    }
}

@keyframes zoomIn {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }

    50% {
        opacity: 1;
    }
}

.zoomIn {
    -webkit-animation-name: zoomIn;
    /* animation-name: zoomIn; */
}
